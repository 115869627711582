import React from "react";
import styled from "styled-components";

const StyledP = styled("p")`
	cursor: not-allowed;
	margin: 2.5em auto;
	position: relative;
	text-align: center;
	z-index: 2;

	span {
		display: inline-block;
		background: #fff;
		padding: 0 1em;
	}
	&:after {
		content: "";
		width: 100%;
		height: 1px;
		border-bottom: 1px solid currentColor;
		display: block;
		position: absolute;
		top: 50%;
		z-index: -1;
	}
`;

const AllContentLoaded = props => (
	<StyledP {...{ props }}>
		<span>All content loaded</span>
	</StyledP>
);

export default AllContentLoaded;
