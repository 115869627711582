import React from "react"
import Link from "gatsby-link"
import Page from "../templates/page"
import PostFeed from "../components/PostFeed"
import Metadata from "../components/shared/Metadata"
import { SHOW_PROJECTS } from "../utilities/helpers"

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges: allPosts },
  },
}) => {
  const posts = allPosts.map(({ node }) => node)
  return (
    <Page id="home">
      <Metadata
        frontmatter={{
          title: "Home | Geoff Davis",
          og_url: "/",
          twitter_card: "summary_large_image",
          twitter_creator: "@gdavis92",
        }}
      />

      <h2>
        Hello there! My name is Geoff and I like to write code, explore Memphis,
        and find great music.
      </h2>

      <div className="divider">
        <span data-sr-only>section divider</span>
      </div>
      <br />
      <h3>Recent Posts</h3>
      <PostFeed
        {...{ posts }}
        limit={3}
        floodgate={{ showLoadMoreComponent: false }}
        showArchiveLink={false}
        showPinned
      />
      {SHOW_PROJECTS && (
        <span>
          <div className="divider">
            <span data-sr-only>section divider</span>
          </div>
          <h2>Interesting Projects</h2>
          <p>
            See what I have been up to recently, or some cool projects I'm
            highlighting.
          </p>
          <p>
            Visit this link to see the rest of{" "}
            <Link to="/projects">my projects</Link>.
          </p>
        </span>
      )}
    </Page>
  )
}

export default IndexPage

export const IndexPageQuery = graphql`
  query IndexPageQuery {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/posts/" }
        frontmatter: { draft: { ne: true } }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 6
    ) {
      edges {
        node {
          frontmatter {
            pin
            title
            date(formatString: "MMM DD, YYYY")
            feat_img
            feat_img_alt
            author
            excerpt
            path
            external_link
          }
          excerpt(pruneLength: 180)
        }
      }
    }
  }
`
