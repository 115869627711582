import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome"
import { faStar } from "@fortawesome/fontawesome-pro-solid"
import { faUser, faCalendarAlt } from "@fortawesome/fontawesome-pro-regular"
import Link from "gatsby-link"
import ConditionalLink from "./ConditionalLink"
import { slugify } from "../../utilities/functions"
import { spectreLinkResets } from "../../utilities/helpers"

const colors = {
  gray: {
    base: "#50596c",
    dark: "#667189",
  },
}

const FeaturedImage = styled("img")`
  width: 100%;
  height: 188px;
  pointer-events: none;
`

const CardTitle = styled.h3`
  line-height: 1.45;
`
const CardAuthor = styled.span``
const CardDate = styled.span`
  display: inline-block;
  font-size: 0.75rem;
  margin-left: 0.1rem;
`

const PostCard = ({
  pin: isPinned,
  title,
  author,
  excerpt,
  date,
  feat_img,
  feat_img_alt,
  link,
  external_link,
}) => {
  const isExternalLink = external_link ? true : false
  const postLink = isExternalLink ? external_link : link
  const postLinkDomain = isExternalLink
    ? external_link
        .split("://")[1]
        .substr(0, external_link.split("://")[1].indexOf("/"))
        .replace(/^www\./, "")
    : null
  const postLinkAttrs = {
    rel: isExternalLink ? "noopener noreferrer" : null,
    target: isExternalLink ? "_blank" : null,
  }

  const PostCardLink = styled(ConditionalLink({ isExternalLink }))`
    ${spectreLinkResets};

    .text-red {
      color: #ca1211;
    }
  `

  /** box-shadow: 0 1px 4px #e7e9ed;
      .card {
        border-color: #b4b6ba;
      } */

  const PinnedPostComponent = isPinned ? (
    <span title="Pinned post">
      <FA icon={faStar} style={{ color: "gold" }} aria-label="pinned post" />
      &nbsp;
    </span>
  ) : null

  const FeaturedImageComponent = feat_img ? (
    <figure className="figure card-image" role="group">
      <FeaturedImage
        src={`/static/images/posts/${slugify(title)}.jpg`}
        alt={feat_img_alt ? feat_img_alt : `source: ${feat_img}`}
        className="img-responsive img-fit-cover"
      />
    </figure>
  ) : null

  const SubtitleComponent = author ? (
    <h4 className="card-subtitle h5">
      <small>
        <FA
          icon={faUser}
          style={{ color: "#ca1211" }}
          aria-label="author icon"
          title="by:"
        />
      </small>{" "}
      <CardAuthor aria-label={`Author: ${author}`}>{author}</CardAuthor>
      <br />
      <small>
        <FA
          icon={faCalendarAlt}
          style={{ color: "#ca1211" }}
          aria-label="calendar icon"
          title="date:"
        />
      </small>{" "}
      <CardDate aria-label={`Published on: ${date}`}>{date}</CardDate>
    </h4>
  ) : null

  const ExcerptComponent = excerpt ? (
    <p
      className="card-body"
      dangerouslySetInnerHTML={{ __html: excerpt.replace(/\{date\}/, "") }}
    />
  ) : null

  const FooterComponent = external_link ? (
    <footer className="card-footer">
      <i className="text-red">via {postLinkDomain}</i>
    </footer>
  ) : null

  return (
    <PostCardLink to={postLink} {...postLinkAttrs}>
      <article className="card">
        {FeaturedImageComponent}
        <header className="card-header">
          <CardTitle className="card-title h4">
            {PinnedPostComponent}
            {title}
          </CardTitle>
          {SubtitleComponent}
        </header>
        {ExcerptComponent}
        {FooterComponent}
      </article>
    </PostCardLink>
  )
}

export default PostCard
