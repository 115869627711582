import React from "react"
import styled from "styled-components"
import Floodgate from "react-floodgate/dist/floodgate.cjs.js"
import AllContentLoaded from "../shared/AllContentLoaded"
import Grid from "../shared/Grid"
import Post from "../shared/PostCard"
import { slugify } from "../../utilities/functions"
import LoadMoreButton from "../shared/LoadMoreButton"

const StyledGrid = styled(Grid)`
  margin: 1em auto;
`

const PostFeedGrid = ({ children }) => (
  <StyledGrid
    component="div"
    alignItems="start"
    column_gap={{ 0: 0, 640: "1em" }}
    row_gap="1em"
    template_columns={{
      0: "repeat(1,100%)",
      640: "repeat(2,calc(50% - .5em))",
      1024: "repeat(3,calc(33% - .5em))",
    }}
  >
    {children}
  </StyledGrid>
)

const PostFeed = (
  {
    posts,
    limit,
    floodgate,
    showArchiveLink,
    showPinned,
    hideExcerpts,
    archives: isArchives,
  } = {
    posts: [],
  }
) => {
  if (isArchives) {
    const safeFloodgateConfig = {
      data: posts,
      initial: 9,
      increment: 3,
      showLoadMoreComponent: true,
      ...floodgate,
    }
    return (
      <Floodgate {...safeFloodgateConfig}>
        {({ items, loadNext, loadComplete }) => {
          const LoadMoreComponent =
            !loadComplete && safeFloodgateConfig.showLoadMoreComponent ? (
              <LoadMoreButton onClick={loadNext} />
            ) : safeFloodgateConfig.showLoadMoreComponent ? (
              <AllContentLoaded />
            ) : null
          return (
            <section>
              <PostFeedGrid>{items}</PostFeedGrid>
              {LoadMoreComponent}
            </section>
          )
        }}
      </Floodgate>
    )
  } else {
    const pinnedPosts = posts
      .map((post, i) => (post.frontmatter.pin ? post : false))
      .filter(p => p)
    const pinnedPostsKeys = posts
      .map((post, i) => (post.frontmatter.pin ? `${i}` : false))
      .filter(pI => pI)
      .map(k => parseInt(k))

    const nonPinnedPosts = posts.filter((p, i) => !pinnedPostsKeys.includes(i))
    const postsToMap = showPinned ? [...pinnedPosts, ...nonPinnedPosts] : posts
    const Posts = postsToMap.length
      ? postsToMap
          .filter((_, i) => {
            if (limit) return i < limit
            return true
          })
          .map(
            ({
              excerpt,
              frontmatter: {
                title,
                excerpt: fmExcerpt,
                path: link,
                ...restProps
              },
            }) => {
              return (
                <Post
                  key={slugify(title)}
                  {...{
                    title,
                    link,
                    excerpt: hideExcerpts
                      ? null
                      : fmExcerpt
                      ? fmExcerpt
                      : excerpt,
                    ...restProps,
                  }}
                />
              )
            }
          )
      : null

    const safeFloodgateConfig = {
      data: Posts,
      initial: 9,
      increment: 3,
      showLoadMoreComponent: true,
      ...floodgate,
    }

    return (
      <Floodgate {...safeFloodgateConfig}>
        {({ items, loadNext, loadComplete }) => {
          const LoadMoreComponent =
            !loadComplete && safeFloodgateConfig.showLoadMoreComponent ? (
              <LoadMoreButton onClick={loadNext} />
            ) : safeFloodgateConfig.showLoadMoreComponent ? (
              <AllContentLoaded />
            ) : null
          return (
            <section>
              <PostFeedGrid>{items}</PostFeedGrid>
              {LoadMoreComponent}
            </section>
          )
        }}
      </Floodgate>
    ) //<PostFeedGrid>{Posts}</PostFeedGrid>;
  }
}

export default PostFeed
