import React from "react";
import styled from "styled-components";
import { grid } from "../../utilities/helpers";

const defaultGridProps = {
  component: "div",
  children: null,
  restGridProps: []
};

const Grid = ({ component, children, ...restGridProps } = defaultGridProps) => {
  const styledComponent = styled(
    component ? component : defaultGridProps.component
  )`
    display: flex;
    flex-wrap: wrap;
    & > * {
      display: inline-block;
      margin: 1em;
      vertical-align: top;
      @media only screen and (min-width: 640px) {
        width: calc(50% - 2em);
      }
    }
    @supports (display: grid) {
      display: grid;
      ${grid("template_areas")};
      ${grid("template_columns")};
      ${grid("template_rows")};
      ${grid("gap")};
      ${grid("row_gap")};
      ${grid("column_gap")};
      ${({ alignContent }) =>
        alignContent ? `align-content: ${alignContent}` : ""};
      ${({ alignItems }) => (alignItems ? `align-items: ${alignItems}` : "")};
      ${({ justifyContent }) =>
        justifyContent ? `justify-content: ${justifyContent}` : ""};
      ${({ justifyItems }) =>
        justifyItems ? `justify-items: ${justifyItems}` : ""};
      & > * {
        display: block;
        margin: 0 auto;
        width: 100%;
      }
    }
  `;
  return React.createElement(styledComponent, { ...restGridProps }, children);
};

export default Grid;
