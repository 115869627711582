import React from "react";
import styled from "styled-components";
import Link from "gatsby-link";

const ConditionalLink = ({ isExternalLink }) => ({
  children,
  to,
  ...restProps
}) =>
  React.createElement(
    isExternalLink ? "a" : Link,
    {
      to: isExternalLink ? null : to,
      href: isExternalLink ? to : null,
      title: isExternalLink ? "Link opens in new tab" : null,
      target: "_blank",
      rel: "noopener noreferrer",
      ...restProps
    },
    children
  );

export default ConditionalLink;
