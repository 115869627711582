import React from "react";
import styled from "styled-components";

const Button = styled("button")`
  display: block !important;
  font-size: 1.5em !important;
  margin: 1em auto 0;
  padding: 1em 0 1.75em !important;
  width: 100% !important;

  &:focus {
    outline: 0.125em dotted #ca1211;
  }
`;

export default ({ onClick }) => (
  <Button
    type="button"
    role="button"
    className="btn btn-primary"
    onClick={onClick}
  >
    Load More
  </Button>
);
