import React from "react"
import Layout from "../layouts/"

export default ({ children, id, heading, intro }) => {
  const pageId = id.toLowerCase().replace(/\s/g, "-")
  const PageHeading = () =>
    heading && heading !== "" ? (
      <header>
        <h1 itemProp="about">{heading}</h1>
        {intro && (
          <p
            itemProp="description"
            dangerouslySetInnerHTML={{ __html: intro }}
          />
        )}
      </header>
    ) : null
  return (
    <Layout>
      <section id="content" data-id={pageId}>
        <PageHeading />
        {children}
      </section>
    </Layout>
  )
}
